import { eventDetail } from "../data/eventDetail";

export const Footer = () => {
  return (
    <footer className="bg-blue-600 p-4 text-white text-center">
      <p>© 2024 {eventDetail.name}. All rights reserved.</p>
      <div className="mt-2">
        <a href={eventDetail.facebook} className="px-4">
          Facebook
        </a>
        <a href={eventDetail.twitter} className="px-4">
          Twitter
        </a>
        <a href={eventDetail.linkedin} className="px-4">
          LinkedIn
        </a>
      </div>
    </footer>
  );
};
