export const faqs = [
  {
    question: "What is a DevFest?",
    answer: "DevFest is a community-led, developer event hosted by GDG chapters around the globe."
  },
  {
    question: "What are GDGs?",
    answer: "GDGs (Google Developer Groups) are local groups of developers interested in Google products and APIs."
  },
  {
    question: "Who can register for DevFest?",
    answer: "Anyone interested in technology and Google products can register for DevFest."
  },
  {
    question: "What is DevFest Profile?",
    answer: "DevFest Profile is your personalized profile that tracks your attendance and participation in DevFest events."
  },
  {
    question: "How do I create my DevFest developer profile?",
    answer: "You can create your DevFest developer profile by signing up on the DevFest website and filling in the required details."
  }
];