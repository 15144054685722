import { organizers } from '../data/organizer';
import { projectFlag } from '../utils/projectUtils';

export const OrganizerHero = () => {
  return (
    <section id="organizers" className="py-16 bg-gray-100">
      <div className={`container mx-auto gallery-container ${projectFlag.contentPaddingClass}`}>
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">Organizers</h2>
        <div className="flex overflow-x-auto space-x-8 py-4">
          {organizers.map((organizer, index) => (
            <div
              key={index}
              className="organizer-card bg-white p-6 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:scale-105"
              style={{ minWidth: '250px' }}
            >
              <div
                className="bg-cover bg-center h-40 w-40 mx-auto rounded-full mb-4 shadow-md"
                style={{ backgroundImage: `url(${organizer.picture})` }}
              ></div>
              <h3 className="text-2xl font-semibold mb-2 text-gray-700">{organizer.name}</h3>
              <p className="text-gray-600 mb-1">{organizer.title}</p>
              <a
                href={organizer.linkedId}
                target="_blank"
                rel="noreferrer"
                className="mt-2 inline-block text-blue-500 hover:text-blue-700 transition-colors duration-300"
              >
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};