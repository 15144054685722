import { getAssetsUrl } from "../utils/projectUtils";

export const applyAsSpeakerLink =
  'https://docs.google.com/forms/d/17QlRoXqtzNrrOUUphzEFUYNWSVArTHcI8Y9VtRkrG94/viewform?edit_requested=true';
export const MicPicture =
  getAssetsUrl('images/mic.png');

export const speakers = [
  {
    name: 'Speaker 1',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/jansen.avif'),
  },
  {
    name: 'Speaker 2',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/arnold.jpg'),
  },
  {
    name: 'Speaker 3',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/jansen.avif'),
  },
  {
    name: 'Speaker 4',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/arnold.jpg'),
  },
  {
    name: 'Speaker 5',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/jansen.avif'),
  },
  {
    name: 'Speaker 6',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/arnold.jpg'),
  },
  {
    name: 'Speaker 7',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/jansen.avif'),
  },
  {
    name: 'Speaker 8',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/arnold.jpg'),
  },
  {
    name: 'Speaker 9',
    title: 'Title',
    linkedId: 'https://www.linkedin.com',
    picture: getAssetsUrl('/speakers/jansen.avif'),
  },
];
