const currentDate = new Date();
const earlyBirdEndDate = new Date('2024-10-01');

export const shouldShowEarlyBird = currentDate < earlyBirdEndDate;

export const ticketBuyLink = 'https://devfest-auckland-2024.lilregie.com/booking/attendees/new';

export const ticketDetails = [
  {
    name: 'Student',
    price: '15',
    benefits: [
      'Access to all sessions',
      'Conference materials',
      'Lunch and snacks',
    ],
  },
  {
    name: 'General',
    price: '40',
    benefits: [
      'Access to all sessions',
      'Conference materials',
      'Lunch and snacks',
    ],
  },
  {
    name: 'Student',
    price: '20',
    benefits: [
      'Access to all sessions',
      'Conference materials',
      'Lunch and snacks',
    ],
  },
  {
    name: 'General',
    price: '50',
    benefits: [
      'Access to all sessions',
      'Conference materials',
      'Lunch and snacks',
    ],
  },
];
