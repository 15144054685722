import { getAssetsUrl } from "../utils/projectUtils";

export const organizers = [
  {
    name: 'Dilum De Silva',
    title: 'Mobile Engineer / GDE Flutter',
    linkedId: 'https://dilumdesilva.dev/',
    picture:
      getAssetsUrl('organizers/dilumdesilva.png'),
  },
  {
    name: 'Julius Spencer',
    title: 'Software Developer / GDG Organiser',
    linkedId: 'https://www.linkedin.com/in/juliusspencer/',
    picture:
      getAssetsUrl('organizers/juliusspencer.jpg'),
  },
  {
    name: 'Nic Tolentino',
    title: 'Staff Engineer / GDG Organiser',
    linkedId: 'https://www.linkedin.com/in/nic-tolentino-32663a66/',
    picture:
      getAssetsUrl('organizers/nictolentino.jpg'),
  },
  {
    name: 'Mark Qiu',
    title: 'Data Engineer / GDG Organiser',
    linkedId: 'https://www.linkedin.com/in/mark-qiu/',
    picture:
      getAssetsUrl("organizers/placeholder.png"),
  },
  {
    name: 'Jessica Lowe',
    title: 'Software Engineer / GDSC Organiser / Women Techmakers Ambassador',
    linkedId: 'https://www.linkedin.com/in/jess-lowe/',
    picture:
    getAssetsUrl("organizers/jessicalowe.jpg"),
  },
  {
    name: 'Aaron Worsnop',
    title: 'GDSC UoA Lead / GDSC Organiser',
    linkedId: 'https://www.linkedin.com/in/aaronworsnop/',
    picture:
    getAssetsUrl("organizers/aaronworsnop.jpg"),
  },
  {
    name: 'Brian',
    title: 'Full Stack Developer / GDG Organiser',
    linkedId: 'https://www.linkedin.com/in/sabrian-jufenlindo-brian-b78179122/',
    picture: getAssetsUrl("organizers/brian.jpg"),
  },
];
