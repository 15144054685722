import './App.css';
import { Navigation } from './components/Navigation';
import { WelcomeHero } from './hero/WelcomeHero';
import { EventDetailHero } from './hero/EventDetailHero';
import { SpeakerHero } from './hero/SpeakerHero';
import { EventScheduleHero } from './hero/EventScheduleHero';
import { TicketHero } from './hero/TicketHero';
import { OrganizerHero } from './hero/OrganizerHero';
import { GalleryHero } from './hero/GalleryHero';
import { FAQHero } from './hero/FAQHero';
import { Footer } from './components/Footer';
import { SponsorHero } from './hero/SponsorHero';
import { projectFlag } from './utils/projectUtils';

function App() {
  return (
    <>
      <Navigation />
      <WelcomeHero />
      <EventDetailHero />
      <SponsorHero />
      <SpeakerHero />
      {projectFlag.showEventScheduleSection && <EventScheduleHero />}
      <TicketHero />
      <OrganizerHero />
      <GalleryHero />
      <FAQHero />
      <Footer />
    </>
  );
}

export default App;
