import { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import { galleries } from '../data/gallery';
import { projectFlag } from '../utils/projectUtils';

export const GalleryHero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  // Preload images when component mounts
  useEffect(() => {
    const preloadImages = () => {
      galleries.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };
    preloadImages();
  }, []);

  const openModal = (image: string) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const GalleryItem = ({ index, style }: { index: number; style: React.CSSProperties }) => (
    <div
      className="gallery-card bg-white p-4 rounded-lg shadow-lg cursor-pointer flex-shrink-0"
      style={{ ...style, width: '380px' }}
      onClick={() => openModal(galleries[index])}
    >
      <img
        src={galleries[index]}
        alt={`Gallery ${index + 1}`}
        className="w-full h-full rounded-lg object-cover"
      />
    </div>
  );

  return (
    <section id="gallery" className="py-16 bg-gray-200">
      <div className={`container mx-auto gallery-container ${projectFlag.contentPaddingClass}`}>
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">Gallery</h2>
        <div className="p-4 flex justify-center">
          <List
            height={300}
            itemCount={galleries.length}
            itemSize={400}
            layout="horizontal"
            width={window.innerWidth - 32} // Adjust based on your container padding
          >
            {GalleryItem}
          </List>
        </div>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
          onClick={handleOverlayClick}
        >
          <div className="relative bg-white rounded-lg p-4 max-w-6xl w-full max-h-screen overflow-auto">
            <button
              className="absolute top-3 right-3 text-black text-3xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={currentImage}
              alt="Enlarged Gallery"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </section>
  );
};
