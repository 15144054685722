import { eventDetail } from '../data/eventDetail';
import { getAssetsUrl, projectFlag } from '../utils/projectUtils';

export const EventDetailHero = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div
        className={`container mx-auto ${projectFlag.contentPaddingClass} flex flex-col lg:flex-row items-center`}
      >
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start mb-8 lg:mb-0">
          <div
            className="bg-gray-300 rounded-full h-80 w-80"
            style={{
              backgroundImage: `url(${getAssetsUrl("images/conference-bg.jpg")})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
        </div>
        <div className="w-full lg:w-2/3 lg:pl-12 text-center lg:text-left">
          <h2 className="text-4xl font-bold mb-4">{eventDetail.name}</h2>
          <p className="text-xl mb-4">{eventDetail.detail}</p>
          <p className="text-lg mb-8">
            <strong>Date: </strong> {eventDetail.date} <br />
            <strong>Location: </strong>
            {eventDetail.location}
          </p>
          <div className="mt-8 flex justify-center lg:justify-start space-x-4">
            <div className="stats-card">
              <h3>{eventDetail.attendees}</h3>
              <p>Attendees</p>
            </div>
            <div className="stats-card">
              <h3>{eventDetail.speakers}</h3>
              <p>Speakers</p>
            </div>
            <div className="stats-card">
              <h3>{eventDetail.sessions}</h3>
              <p>Sessions</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
