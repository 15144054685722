import { ticketDetails, shouldShowEarlyBird, ticketBuyLink } from '../data/ticketDetail';

export const TicketHero = () => {
  const displayTicketDetails = shouldShowEarlyBird
    ? [ticketDetails[0], ticketDetails[1]]
    : [ticketDetails[2], ticketDetails[3]];
  return (
    <section id="tickets" className="py-16 bg-gray-200">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">
          Tickets
        </h2>
        <div className={`grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 gap-8`}>
          <div/>
          {displayTicketDetails.map((ticket, index) => (
            <div
              key={index}
              className="bg-gray-100 border border-gray-300 p-6 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:scale-105"
            >
              <h3 className="text-2xl font-semibold mb-4 text-gray-700">
                {ticket.name}
              </h3>
              <p className="text-gray-600 mb-4 pricing-font">${ticket.price}</p>
              <ul className="text-center">
                {ticket.benefits.map((benefit, index) => (
                  <li key={index} className="mb-2">
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-8 text-center">
          <a
            href={ticketBuyLink} // Assuming all tickets have the same link
            target="_blank"
            rel="noreferrer"
            className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300"
          >
            Buy Now
          </a>
        </div>
      </div>
    </section>
  );
};