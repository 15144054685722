import React, { useRef } from 'react';
import { applyAsSpeakerLink, speakers, MicPicture } from '../data/speaker';
import { projectFlag } from '../utils/projectUtils';

export const SpeakerHero = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!scrollRef.current) return;
    isDown = true;
    scrollRef.current.classList.add('active');
    startX = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    if (scrollRef.current) {
      scrollRef.current.classList.remove('active');
    }
  };

  const handleMouseUp = () => {
    isDown = false;
    if (scrollRef.current) {
      scrollRef.current.classList.remove('active');
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDown || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Multiply the distance moved to increase scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section id="speakers" className="py-32 bg-gray-100">
      <div className={`container mx-auto ${projectFlag.contentPaddingClass}`}>
        {projectFlag.showSpeakerSection && (
          <>
            <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">
              Speakers
            </h2>
            <div
              ref={scrollRef}
              className="scroll-container gallery-scroll overflow-x-scroll cursor-pointer flex space-x-8 py-4"
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              {speakers.map((speaker, index) => (
                <div
                  key={index}
                  className="scroll-item card bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                >
                  <div
                    className="placeholder bg-cover bg-center h-40 w-40 mx-auto rounded-full mb-4 shadow-md"
                    style={{ backgroundImage: `url(${speaker.picture})` }}
                  ></div>
                  <h3 className="text-xl font-semibold text-gray-700">
                    {speaker.name}
                  </h3>
                  <p className="text-gray-600">{speaker.title}</p>
                  <a
                    href={speaker.linkedId}
                    target="_blank"
                    rel="noreferrer"
                    className="mt-2 inline-block text-blue-500"
                  >
                    <i className="fab fa-linkedin"></i> LinkedIn
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {/* <!-- Apply to Become a Speaker --> */}
        <div className="mt-16 text-center">
          <div className="mx-auto mb-4 h-60 w-60 rounded-full shadow-lg p-1 flex items-center justify-center">
            <img
              src={MicPicture}
              alt="Speaker Icon"
              className="h-full w-full object-contain"
            />
          </div>

          <h3 className="text-2xl font-bold text-gray-800">
            Apply to Become a Speaker
          </h3>
          <p className="text-gray-600 mb-6">Share your expertise</p>
          <a
            href={applyAsSpeakerLink}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300"
          >
            Apply Now
          </a>
        </div>
      </div>
    </section>
  );
};
