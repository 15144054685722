import { useState, useRef, useCallback } from 'react';
import { eventDetail } from '../data/eventDetail';
import { projectFlag } from '../utils/projectUtils';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <nav className="bg-blue-600 p-4 text-white fixed w-full top-0 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <a href="#" className="font-bold text-xl">
          {eventDetail.name}
        </a>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
        </div>
        <div
          ref={dropdownRef}
          className={`${
            isOpen ? 'block' : 'hidden'
          } md:flex md:items-center absolute top-20 left-0 w-full md:static md:w-auto bg-blue-700 md:bg-transparent`}
          style={{
            maxHeight: isOpen ? '60vh' : '0',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          <div className="overflow-y-auto max-h-40vh">
            <a
              href="#sponsors"
              className="block md:inline-block px-4 py-4 md:py-2"
            >
              Sponsors
            </a>
            <a
              href="#speakers"
              className="block md:inline-block px-4 py-4 md:py-2"
            >
              Speakers
            </a>
            {projectFlag.showEventScheduleSection && (
              <a
                href="#schedule"
                className="block md:inline-block px-4 py-4 md:py-2"
              >
                Schedule
              </a>
            )}
            <a
              href="#tickets"
              className="block md:inline-block px-4 py-4 md:py-2"
            >
              Tickets
            </a>
            <a
              href="#organizers"
              className="block md:inline-block px-4 py-4 md:py-2"
            >
              Organizers
            </a>
            <a
              href="#gallery"
              className="block md:inline-block px-4 py-4 md:py-2"
            >
              Gallery
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
