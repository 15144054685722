export const eventDetail = {
  name: 'DevFest Auckland',
  detail:
    'The annual developer festival is back again in 2024. Join us for a day of technical talks, workshops, and networking.',
  date: '10th November 2024',
  location: 'University of Auckland',
  attendees: '200+',
  sessions: '20+',
  speakers: '20+',
  facebook: 'https://lnkd.in/gmnu6jfh',
  twitter: 'https://twitter.com/gdgauckland',
  linkedin: 'https://www.linkedin.com/company/gdg-auckland',
};
