import { getAssetsUrl } from "../utils/projectUtils";

export const sponsorPackage = getAssetsUrl('documents/DevFest_2024_Sponsor_Pack.pdf');

export const sponsors = [
  {
    name: 'Google',
    linkedId: 'https://www.google.com/',
    picture: getAssetsUrl('organizers/google.png'),
  },
  {
    name: 'JSA',
    linkedId: 'https://www.juliusspencer.co.nz/',
    picture: getAssetsUrl('sponsors/jsa.jpg'),
  },
];
