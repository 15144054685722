import React from 'react';
import { sponsorPackage, sponsors } from '../data/sponsor';
import { projectFlag } from '../utils/projectUtils';

export const SponsorHero = () => {
  const isCentered = sponsors.length < 3;
  return (
    <section id="sponsors" className="py-32 bg-gray-200">
      <div className={`container mx-auto ${projectFlag.contentPaddingClass}`}>
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">
          Our Sponsors
        </h2>
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 justify-center text-center`}
        >
          {/* Remove Hardcoded div for centering */}
          {isCentered && <div />}
          {sponsors.map((sponsor, index) => (
            <a
              key={index}
              href={sponsor.linkedId}
              target="_blank"
              rel="noreferrer"
              className="sponsor-card group transform transition-transform duration-300 hover:scale-105"
            >
              <div
                className="placeholder bg-cover bg-center h-40 w-40 mx-auto rounded-full mb-4 shadow-lg"
                style={{ backgroundImage: `url(${sponsor.picture})` }}
              ></div>
              <h3 className="text-xl font-semibold text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {sponsor.name}
              </h3>
            </a>
          ))}
        </div>
        <div className="mt-16 text-center">
          <h3 className="text-2xl font-bold text-gray-800">
            Apply to Become a Sponsor
          </h3>
          <p className="text-gray-600 mb-6">Join us in making a difference</p>
          <a
            href={sponsorPackage}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300"
          >
            Download sponsorship prospectus
          </a>
        </div>
      </div>
    </section>
  );
};
