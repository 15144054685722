export enum EventDay {
  Day1 = 'Day 1',
  Day2 = 'Day 2',
}

interface Event {
  time: string;
  speaker: string;
  description: string;
}

type EventSchedules = {
  [key in EventDay]: Event[];
};

export const eventSchedules: EventSchedules = {
  [EventDay.Day1]: [
    {
      time: '9:00 AM',
      speaker: 'Speaker 1',
      description: 'Opening Remarks',
    },
    {
      time: '10:00 AM',
      speaker: 'Speaker 2',
      description: 'Keynote Address',
    },
    {
      time: '11:00 AM',
      speaker: 'Speaker 3',
      description: 'Panel Discussion',
    },
    {
      time: '12:00 PM',
      speaker: 'Speaker 4',
      description: 'Lunch Break',
    },
    {
      time: '1:00 PM',
      speaker: 'Speaker 5',
      description: 'Workshop 1',
    },
    {
      time: '2:00 PM',
      speaker: 'Speaker 6',
      description: 'Workshop 2',
    },
    {
      time: '3:00 PM',
      speaker: 'Speaker 7',
      description: 'Networking Session',
    },
    {
      time: '4:00 PM',
      speaker: 'Speaker 8',
      description: 'Closing Remarks',
    },
    {
      time: '5:00 PM',
      speaker: 'Speaker 9',
      description: 'End of Day 1',
    },
    {
      time: '6:00 PM',
      speaker: 'Speaker 10',
      description: 'Dinner & Networking',
    },
  ],
  [EventDay.Day2]: [
    {
      time: '9:00 AM',
      speaker: 'Speaker 1',
      description: 'Day 2 Opening Remarks',
    },
    {
      time: '10:00 AM',
      speaker: 'Speaker 2',
      description: 'Keynote Address',
    },
    {
      time: '11:00 AM',
      speaker: 'Speaker 3',
      description: 'Panel Discussion',
    },
    {
      time: '12:00 PM',
      speaker: 'Speaker 4',
      description: 'Lunch Break',
    },
    {
      time: '1:00 PM',
      speaker: 'Speaker 5',
      description: 'Workshop 1',
    },
    {
      time: '2:00 PM',
      speaker: 'Speaker 6',
      description: 'Workshop 2',
    },
    {
      time: '3:00 PM',
      speaker: 'Speaker 7',
      description: 'Networking Session',
    },
    {
      time: '4:00 PM',
      speaker: 'Speaker 8',
      description: 'Closing Remarks',
    },
    {
      time: '5:00 PM',
      speaker: 'Speaker 9',
      description: 'End of Day 2',
    },
    {
      time: '6:00 PM',
      speaker: 'Speaker 10',
      description: 'Dinner & Networking',
    },
  ],
};
