import React, { useState } from 'react';

interface FAQItemProps {
  question: string;
  answer: string;
}

export const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={toggleFAQ}
        className="w-full text-left py-4 flex justify-between items-center faq-question"
      >
        <span>{question}</span>
        <span
          className={`faq-icon transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          &#x25bc;
        </span>
      </button>
      <div
        className={`faq-answer text-gray-600 px-4 pb-4 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        {answer}
      </div>
    </div>
  );
};
