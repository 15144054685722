import { FAQItem } from '../components/FAQItem';
import { faqs } from '../data/faq';
import { projectFlag } from '../utils/projectUtils';

export const FAQHero = () => {
  return (
    <section id="faq" className="py-16 bg-gray-100">
      <div className={`container mx-auto ${projectFlag.contentPaddingClass}`}>
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">
          Frequently Asked Questions
        </h2>
        <div className="text-center mb-8">
          <p className="text-gray-600">
            Need answers? Everything is {``}
            <a href="#" className="text-blue-500">
              here
            </a>
            .
          </p>
        </div>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};
