import { eventDetail } from '../data/eventDetail';
import { getAssetsUrl } from '../utils/projectUtils';

export const WelcomeHero = () => {
  return (
    <section
      className="hero h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${getAssetsUrl("images/conference-bg.jpg")})` }}
    >
      <div className="h-full flex items-center justify-center text-white">
        <h1 className="text-5xl font-bold bg-black bg-opacity-50 p-4 rounded">
          Welcome to {eventDetail.name}
        </h1>
      </div>
    </section>
  );
};
