import { useState } from 'react';
import { EventDay, eventSchedules } from '../data/eventSchedule';
import { projectFlag } from '../utils/projectUtils';

export const EventScheduleHero = () => {
  const [selectedDay, setSelectedDay] = useState<EventDay>(
    Object.keys(eventSchedules)[0] as EventDay
  );

  return (
    <section id="schedule" className="py-16 bg-gray-200">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Event Schedule</h2>
        <div className={`tabs ${projectFlag.contentPaddingClass}`}>
          <ul className="flex justify-center mb-4">
            {Object.keys(eventSchedules).map((day, index) => (
              <li key={index} className="mr-2">
                <div
                  onClick={() => setSelectedDay(day as EventDay)}
                  className={`${
                    selectedDay === day
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-500 text-white'
                  } px-4 py-2 rounded cursor-pointer`}
                >
                  {day}
                </div>
              </li>
            ))}
          </ul>
          <div id="day1" className="tab-content">
            <table className="min-w-full bg-gray-800 text-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-700" style={{ width: '150px' }}>Time</th>
                  <th className="py-2 px-4 bg-gray-700" style={{ width: '200px' }}>Speaker</th>
                  <th className="py-2 px-4 bg-gray-700" style={{ width: '300px' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {eventSchedules[selectedDay].map((event, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2" style={{ width: '150px' }}>{event.time}</td>
                    <td className="border px-4 py-2" style={{ width: '200px' }}>{event.speaker}</td>
                    <td className="border px-4 py-2" style={{ width: '300px' }}>{event.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};